import React from "react";

import styles from "./notification.module.css";

export const Notification = ({
  message,
  type = "info",
  onClose,
  duration = 3000,
}) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className={`${styles.notification} ${styles[`notification-${type}`]}`}>
      <p className={styles.notification__text}>{message}</p>
    </div>
  );
};
